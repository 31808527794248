import React from "react";
import View from "components/View";
//ui

function App() {
  return (
    <div className="row">
      <div className="col-xs-12 col-md-offset-3 col-md-6">
        <View />
      </div>
    </div>
  );
}

export default App;
