import React from "react";

//ui
import {
  Divider,
  FormGroup,
  InputGroup,
  NumericInput,
  Switch
} from "@blueprintjs/core";

function ActivePanel({ data, onChange }) {
  return (
    <div>
      <FormGroup
        label="Taxpayer name"
        helperText="This is just to help you keep track. This information is not sent anywhere"
      >
        <InputGroup
          value={data.name}
          onChange={e => onChange("name", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Does this taxpayer have any income/expenses"
        helperText="Click no if for example this is a child over 18 with no income/expenses and only filling for HST credit"
      >
        <Switch
          large
          innerLabelChecked="Yes"
          innerLabel="No"
          checked={!data.nil}
          onChange={e => onChange("nil", !e.target.checked)}
        />
      </FormGroup>

      {data.nil ? null : (
        <div>
          <Divider />
          <br />
          <FormGroup
            label="Are you a full time student"
            helperText="Example: full time university student"
          >
            <Switch
              large
              innerLabelChecked="Yes"
              innerLabel="No"
              checked={data.student}
              onChange={e => onChange("student", e.target.checked)}
            />
          </FormGroup>
          <FormGroup
            label="How many t slips do you have?"
            helperText="Including T3, T4, T5, T5008, T2202, etc.."
          >
            <NumericInput
              fill
              min={0}
              minorStepSize={1}
              majorStepSize={1}
              value={data.tslip}
              onValueChange={n => {
                var t = 0;
                if (n > 0) {
                  t = n;
                }
                onChange("tslip", t);
              }}
            />
          </FormGroup>
          <FormGroup
            label="How many RRSP/donations/medical slips do you have?"
            helperText="Each prescription summary from pharmacy counts as 1"
          >
            <NumericInput
              fill
              min={0}
              minorStepSize={1}
              majorStepSize={1}
              value={data.misc}
              onValueChange={n => {
                var t = 0;
                if (n > 0) {
                  t = n;
                }
                onChange("misc", t);
              }}
            />
          </FormGroup>
          <FormGroup
            label="Did you trade any securities outside a registered account"
            helperText="Registered accounts includes RRSP, TFSA, RESP, etc..."
          >
            <Switch
              large
              innerLabelChecked="Yes"
              innerLabel="No"
              checked={data.trading > 0}
              onChange={e => {
                if (e.target.checked) {
                  onChange("trading", 1);
                } else {
                  onChange("trading", 0);
                }
              }}
            />
          </FormGroup>
          {data.trading > 0 ? (
            <FormGroup
              label="Number of investment trading transactions"
              helperText="Counts as 1 if you have an accurate gain/loss summary"
            >
              <NumericInput
                fill
                min={1}
                minorStepSize={1}
                majorStepSize={1}
                value={data.trading}
                onValueChange={n => {
                  var t = 0;
                  if (n > 0) {
                    t = n;
                  }
                  onChange("trading", t);
                }}
              />
            </FormGroup>
          ) : null}
          <Divider />
          <h5 className="bp3-heading" style={{ marginTop: "10px" }}>
            Special circumstances
          </h5>
          <p>
            If you have any of the following, it is best to contact us directly
            for a more accurate quote. The pricing presented here is an
            estimated starting price only and may vary (in either direction)
            significantly depending on the difficulty involved
          </p>
          <Switch
            large
            innerLabelChecked="Yes"
            innerLabel="No"
            checked={data.rental}
            onChange={e => onChange("rental", e.target.checked)}
            label="Have any rental properties?"
          />
          <Switch
            large
            innerLabelChecked="Yes"
            innerLabel="No"
            checked={data.selfemp}
            onChange={e => onChange("selfemp", e.target.checked)}
            label="Have self employment income?"
          />
          <Switch
            large
            innerLabelChecked="Yes"
            innerLabel="No"
            checked={data.empexp}
            onChange={e => onChange("empexp", e.target.checked)}
            label="Have employment expenses?"
          />
        </div>
      )}
    </div>
  );
}

export default ActivePanel;
