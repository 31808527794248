import React from "react";
import produce from "immer";

import ActivePanel from "./ActivePanel";
//ui
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Elevation,
  FormGroup,
  HTMLSelect
} from "@blueprintjs/core";

const calc = data => {
  var result = { estimate: 0, complex: false };
  //loop through and calculate price per
  for (var i = 0; i < data.length; i++) {
    const base = 70;
    var d = data[i];
    var pp = 0;
    pp += base;
    //count slips
    if (d.nil) {
      pp -= 40;
    } else if (
      //base case
      d.tslip <= 5 &&
      d.misc <= 5 &&
      d.trading === 0
    ) {
      //do nothing
    } else if (
      //simple case
      d.tslip <= 10 &&
      d.misc <= 10 &&
      d.trading < 10
    ) {
      pp += 40;
    } else if (
      //moderate case
      d.tslip <= 15 &&
      d.misc <= 25 &&
      d.trading < 20
    ) {
      pp += 120;
    } else {
      //complex case
      //complex return, skipping
      pp += 230;
      result.complex = true;
    }
    //check for complex
    if (d.rental) {
      pp += 230;
      result.complex = true;
    }
    if (d.selfemp) {
      pp += 230;
      result.complex = true;
    }
    if (d.empexp) {
      pp += 130;
      result.complex = true;
    }
    //apply student discount
    if (d.student) {
      pp -= 20;
    }
    //make sure per person cost does not fall below $30
    if (pp < 30) {
      pp = 30;
    }
    result.estimate += pp;
  }
  //apply multi discount
  if (data.length > 1) {
    result.estimate -= 10 * data.length;
  }

  return result;
};

const initialState = {
  count: 1,
  data: [
    {
      name: "Taxpayer 1",
      tslip: 0,
      misc: 0,
      trading: 0,
      rental: false,
      selfemp: false,
      empexp: false,
      nil: false,
      student: false
    }
  ],
  cost: 70,
  complex: false,
  active: 0
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case "active/set":
      draft["active"] = action.value;
      break;
    case "return/add":
      draft["count"]++;
      draft["data"].push({
        name: "Taxpayer " + draft["count"],
        tslip: 0,
        misc: 0,
        trading: 0,
        rental: false,
        selfemp: false,
        empexp: false,
        nil: false,
        student: false
      });
      draft["active"] = draft["data"].length - 1;
      break;
    case "return/remove":
      if (action.target < 0 || action.target >= draft["data"].length) {
        return;
      }
      draft["data"].splice(action.target, 1);
      break;

    case "return/value/set":
      //check if exist
      if (action.key < 0 || action.key >= draft["data"].length) {
        return;
      }
      if (action.target in draft["data"][action.key]) {
        draft["data"][action.key][action.target] = action.value;
        //check if nil
        if (action.target === "nil") {
          draft["data"][action.key]["tslip"] = 0;
          draft["data"][action.key]["misc"] = 0;
          draft["data"][action.key]["trading"] = 0;
          draft["data"][action.key]["rental"] = false;
          draft["data"][action.key]["selfemp"] = false;
          draft["data"][action.key]["empexp"] = false;
          draft["data"][action.key]["student"] = false;
        }
      }
      break;
    default:
      break;
  }
  var r = calc(draft["data"]);
  draft["cost"] = r.estimate;
  draft["complex"] = r.complex;
});

function View() {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  // const [active, setActive] = React.useState(0);
  var active = state.active;
  const setActive = val => dispatch({ type: "active/set", value: val });
  const handleDelete = () => {
    dispatch({ type: "return/remove", target: active });
    if (active >= state.data.length - 1) {
      setActive(state.data.length - 2);
    }
  };
  const onChange = (target, value) => {
    dispatch({
      type: "return/value/set",
      key: active,
      target: target,
      value: value
    });
  };
  var options = state.data.map((e, i) => {
    return { label: e.name, value: i };
  });
  return (
    <div className="box">
      <div className="row center-xs">
        <div className="col-xs-12">
          <h2 className="bp3-heading">PolarisCPA - T1 Cost Estimator</h2>
          <br />
        </div>
      </div>
      <div className="row start-xs">
        <div className="col-xs-12">
          <Callout intent="primary" title="COVID-19">
            <p>
              The COVID-19 outbreak is an unprecedent global public health issue
              affecting us all. In order to do our part in this, we are offering
              a special discount of <b>up to 70%</b> off our quoted price for
              any individual or family affected financially by this outbreak.
              Please contact us for more information.
            </p>
          </Callout>
          <h5 className="bp3-heading">Instructions:</h5>
          <p>
            Click the add button to add a new taxpayer. Fill in the required
            information below for each taxpayer that needs filling. Use the
            dropdown to switch between taxpayers.
          </p>
        </div>
      </div>
      <div className="row start-xs">
        <div className="col-xs-12">
          <div>
            <Card elevation={Elevation.TWO}>
              <FormGroup helperText="Select a taxpayer and fill out details for cost estimate">
                <HTMLSelect
                  fill
                  options={options}
                  value={active}
                  onChange={e => setActive(e.currentTarget.value)}
                />
              </FormGroup>
              <ButtonGroup fill>
                <Button
                  onClick={() => dispatch({ type: "return/add" })}
                  icon="new-person"
                  intent="primary"
                >
                  Add Return
                </Button>
                <Button
                  disabled={state.data.length === 1}
                  onClick={handleDelete}
                  icon="delete"
                  intent="danger"
                >
                  Delete Return
                </Button>
              </ButtonGroup>
            </Card>
            <br />
            <Card elevation={Elevation.TWO}>
              <ActivePanel data={state.data[active]} onChange={onChange} />
            </Card>
            <br />
            <Card elevation={Elevation.TWO}>
              {state.complex ? (
                <Callout intent="primary" title="Complex situation">
                  <p>
                    Your situation may be more complex. Please contact us
                    directly for a more accurate quote.
                  </p>
                </Callout>
              ) : null}
              <h5 className="bp3-heading" style={{ marginTop: "10px" }}>
                Estimated price:{" "}
                {state.cost.toLocaleString("en-US", {
                  style: "currency",
                  currency: "CAD"
                })}
              </h5>
              <br />
              <a
                role="button"
                className="bp3-button bp3-fill bp3-intent-primary"
                tabIndex="0"
                href="https://calendly.com/polariscpa"
              >
                Book an online meeting now
              </a>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default View;
